import CloseButton from '../../Atoms/Buttons/CloseButton';
import { H2 } from '../../Atoms/Typography/Headings/Heading';
import { styled } from '../../stitches.config';

type Props = {
  header: string;
  close: () => void;
};

export const ModalHeader = ({ header, close }: Props) => {
  return (
    <ModalHeaderWrapper>
      <H2 noMargin css={StyledCartHeader}>
        {header}
      </H2>
      <CloseWrapper>
        <CloseButton
          size="l"
          css={{ marginLeft: 'auto' }}
          onClick={close}
          bgColor="primary"
        />
      </CloseWrapper>
    </ModalHeaderWrapper>
  );
};

const ModalHeaderWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

const CloseWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  zIndex: 9,
  top: 16,
  right: 16,
  '@mediaMinLarge': {
    top: 24,
    right: 24,
  },
});

const StyledCartHeader = {
  mt: 4,
  mb: 3,
  px: 4,
  '@mediaMinLarge': {
    mt: 10,
    mb: 11,
    px: 6,
  },
};
