import KexLink from 'Kex/KexLink';
import ProductList from 'Organisms/ProductList/ProductList';
import { GetCart } from 'Shared/Cart/Cart';
import { useEffect, useState } from 'react';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';
import Modal from 'Organisms/Modal/Modal';
import { H3 } from 'Atoms/Typography/Headings/Heading';
import { ButtonBase, ButtonVariants } from 'Atoms/Buttons/BaseButtonStyle';
import Paragraph from 'Atoms/Typography/Paragraph/Paragraph';
import { canUseDOM } from 'Shared/Common/Helpers';
import { ModalHeader } from '../Modal/ModalHeader';

export type PropType = {
  isOpen: boolean;
  closeMiniCart: () => void;
};

function MiniCart({ isOpen, closeMiniCart }: PropType) {
  const [renderCart, setRenderCart] = useState<boolean>(false);
  const {
    staticPages: { checkoutPage },
    languageRoute,
  } = useAppSettingsData();
  const { cart, isLoading, isCartEmpty } = GetCart(languageRoute);
  const {
    cartLabels: { open, cartHeader, cartEmptyTitle, cartEmptyDescription },
    commonLabels: { loading },
  } = useTranslationData();

  const currentUrl = canUseDOM() && window.location.href;

  useEffect(() => {
    if (isOpen) {
      setRenderCart(true);
    }
  }, [isOpen]);

  useEffect(() => {
    closeMiniCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUrl]);

  return (
    <Modal showModal={isOpen} noPadding toggle={closeMiniCart} hideCloseButton>
      <MiniCartContainer>
        {renderCart && (
          <>
            {isLoading && <>{loading}</>}

            <ProductListWrapper>
              <ModalHeader header={cartHeader} close={closeMiniCart} />

              {isCartEmpty && (
                <EmptyCartWrapper>
                  <H3 css={StyledEmptyCartTitle}>{cartEmptyTitle}</H3>
                  <Paragraph>{cartEmptyDescription}</Paragraph>
                </EmptyCartWrapper>
              )}
              <ProductList
                products={cart?.lineItems || []}
                isLoading={isLoading}
                allowEdit
                isMiniCart={true}
              />
            </ProductListWrapper>
          </>
        )}
      </MiniCartContainer>
      {!isCartEmpty && (
        <MiniCartBottom>
          <SubTotal>
            <SubTotalText>
              {cart?.totalPrice?.priceRoundedAsString}
            </SubTotalText>
          </SubTotal>
          <OpenCartButton
            href={checkoutPage}
            fullWidth
            underlined={false}
            fontFamily="primary500"
          >
            {open}
          </OpenCartButton>
        </MiniCartBottom>
      )}
    </Modal>
  );
}

const StyledEmptyCartTitle = {
  mt: 0,
  mb: 4,
  fs: 8,
  lineHeight: '$lh125',
};

const ProductListWrapper = styled('div', {});

const EmptyCartWrapper = styled('div', {
  px: 4,
  '@mediaMinLarge': {
    px: 6,
  },
});

const MiniCartBottom = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  bottom: 0,
  left: 0,
  position: 'absolute',
  px: 4,
  pt: 6,
  pb: 10,
  w: '100%',
  backgroundColor: '$primary4',
  '@mediaMinLarge': {
    p: 8,
  },
  zIndex: '$Minicart',
});

const SubTotal = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  mt: 1.25,
  flexBasis: '50%',
});

const SubTotalText = styled('div', {
  fs: 9,
  fontFamily: '$fontPrimary400',
  wordSpacing: '$wordSpacings$fontPrimary',
  lineHeight: '$lh133',
  ls: 'lsn1',
  color: '$textPrimary',
});

const OpenCartButton = styled(KexLink, {
  ...ButtonBase,
  ...ButtonVariants,
  mb: 0,
  flexBasis: '50%',
});

const MiniCartContainer = styled('div', {
  height: '100%',
  paddingBottom: '112px', // height of minicart bottom bar
  overflowY: 'auto',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    display: 'none',
  },
  '&::scrollbar': {
    display: 'none',
  },
});

export default MiniCart;
